import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useRequest from 'hooks/useRequest';

import ContactsForm from 'components/Forms/ContactsForm';
import TrabajadoresForm from 'components/Forms/TrabajadoresForm';
import EquiposForm from 'components/Forms/EquiposForm';
import ProveedoresForm from 'components/Forms/ProveedoresForm';
import UsersFormByBusiness from 'components/Forms/UsersFormByBusiness';
import ClasificacionForm from 'components/Forms/ClasificacionForm';

const moduleTable = {
  contacts: 'administration/contacts_admin',
  trabajadores: 'administration/trabajadores',
  equipos: 'administration/equipos',
  proveedores: 'administration/proveedores_admin',
  formatos: 'empresa/formatos',
  users_proveedor: 'administration/users',
  users_business: 'administration/users',
  clasificacion: 'administration/formats_c'
};

export default function ModuleFormPageById() {
  const { module, id, title, itemId } = useParams();
  const { reqTokenBase } = useRequest();
  const history = useHistory();

  const [reqStatus, setReqStatus] = useState(null);
  const [data, setData] = useState(null);
  const [requestLoading, setReqLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [error, setError] = useState({});
  const [reqResData, setReqResData] = useState(null);
  const [parentBusiness, setParentBusiness] = useState(null);

  const getData = async () => {
    const res = await reqTokenBase.get(`${moduleTable[module]}/${itemId}`);
    setData({ ...res.data, use_text_pass: '' });

    setReqLoading(false);
  };

  const getBusinessData = async () => {
    const res = await reqTokenBase.get(`administration/business_admin/${id}`);
    setParentBusiness(res.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError({});
    setFormLoading(true);

    try {
      if (itemId !== '0') {
        if (module === 'contacts') {
          await reqTokenBase.put(`${moduleTable[module]}/${itemId}`, {
            con_varchar_email: data.con_varchar_email,
            con_varchar_whatsapp: data.con_varchar_whatsapp,
            bus_int_id: id
          });
        }

        if (module === 'trabajadores') {
          await reqTokenBase.put(`${moduleTable[module]}/${itemId}`, {
            tra_varchar_nombres: data.tra_varchar_nombres,
            tra_varchar_apellidos: data.tra_varchar_apellidos,
            tra_varchar_dni: data.tra_varchar_dni,
            bus_int_id: id,
            tra_int_pea: data.tra_int_pea
          });
        }

        if (module === 'equipos') {
          await reqTokenBase.put(`${moduleTable[module]}/${itemId}`, {
            equ_varchar_marca: data.equ_varchar_marca,
            equ_varchar_modelo: data.equ_varchar_modelo,
            equ_varchar_serie: data.equ_varchar_serie,
            bus_int_id: id
          });
        }

        if (module === 'equipos') {
          await reqTokenBase.put(`${moduleTable[module]}/${itemId}`, {
            equ_varchar_marca: data.equ_varchar_marca,
            equ_varchar_modelo: data.equ_varchar_modelo,
            equ_varchar_serie: data.equ_varchar_serie,
            bus_int_id: id
          });
        }

        if (module === 'proveedores') {
          await reqTokenBase.put(`${moduleTable[module]}/${itemId}`, {
            bus_text_name: data.bus_text_name,
            bus_text_identification: data.bus_text_identification,
            bus_text_address: data.bus_text_address,
            bus_text_phone: data.bus_text_phone,
            bus_text_domain: data.bus_text_domain,
            bus_int_pid: id
          });
        }

        if (module === 'users_proveedor' || module === 'users_business') {
          await reqTokenBase.put(`${moduleTable[module]}/${itemId}`, {
            use_text_user: data.use_text_user,
            use_text_name: data.use_text_name,
            use_text_lastname: data.use_text_lastname,
            use_text_phone: data.use_text_phone,
            use_int_admin: data.use_int_admin,
            use_text_email: data.use_text_email,
            use_text_pass: data.use_text_pass,
            bus_int_id: id
          });
        }

        if (module === 'clasificacion') {
          await reqTokenBase.put(`${moduleTable[module]}/${itemId}`, {
            fcl_text_name: data.fcl_text_name,
            bus_int_id: id
          });
        }
      }

      if (itemId === '0') {
        if (module === 'proveedores') {
          const regexDomain = /^@\w[\w.-]/i;
          const regexUser = /^[\w.-]*$/gi;

          let res;

          if (!regexDomain.test(data.bus_text_domain)) {
            setError({
              ...error,
              bus_text_domain:
                "La estructura del dominio debe ser '@dominio', no puedes ingresar caracteres especiales o espacios, solo caracteres alfa-numéricos, guiones y puntos"
            });

            setFormLoading(false);
            return;
          }

          if (!regexUser.test(data.use_text_user)) {
            setError({
              ...error,
              use_text_user:
                'No puedes ingresar caracteres especiales o espacios, solo caracteres alfa-numéricos, guiones y puntos'
            });

            setFormLoading(false);
            return;
          }

          if (!reqResData) {
            res = await reqTokenBase.post(`${moduleTable[module]}`, {
              ...data,
              bus_int_id: id,
              bus_int_pid: id
            });
            setReqResData(res.data);
          }

          await reqTokenBase.post('administration/users', {
            ...data,
            use_int_admin: '0',
            use_text_user:
              data.use_text_user.replace(/\s/g, '') + data.bus_text_domain,
            bus_int_id: res ? res.data.id : reqResData.id
          });

          history.replace(
            `/module/business_admin/${id}/${title}/info/${module}`
          );
          return;
        }

        if (module === 'users_business') {
          await reqTokenBase.post(moduleTable[module], {
            ...data,
            bus_int_id: id,
            use_text_user: `${data?.use_text_user}${parentBusiness?.bus_text_domain}`
          });

          history.replace(
            `/module/business_admin/${id}/${title}/info/${module}`
          );

          return;
        }

        await reqTokenBase.post(moduleTable[module], {
          ...data,
          bus_int_id: id
        });
      }

      history.replace(`/module/business_admin/${id}/${title}/info/${module}`);
    } catch (error) {
      console.log(error);

      if (error.response) {
        setError(error.response?.data.messages);
        setFormLoading(false);
        return;
      }

      setReqStatus(false);
      setFormLoading(false);
    }
  };

  const handleChange = ({ target }) => {
    setData({ ...data, [target.name]: target.value });
  };

  const handleGoBack = () => {
    if (module === 'formatos') {
      return history.replace(`/module/types/${id}/${title}/info/${module}`);
    }
    history.replace(`/module/business_admin/${id}/${title}/info/${module}`);
  };

  useEffect(() => {
    if (itemId !== '0') {
      return getData();
    }

    if (module === 'users_business' || module === 'users_proveedor') {
      getBusinessData();
      // setData({ ...data, bus_int_id: id });
    }

    setReqLoading(false);
    //eslint-disable-next-line
  }, []);

  if (requestLoading) {
    return (
      <div className="w-100 h-100">
        <div className="py-5 d-flex align-items-center justify-content-center w-100 bg-light my-auto">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-100 h-100">
      <div className="d-flex justify-content-start align-items-center w-100 mb-3">
        <button
          className="btn btn-secondary me-auto btn-sm"
          onClick={handleGoBack}
          type="button"
          title="Volver atrás"
        >
          <i className="fa fas fa-caret-left me-2 fa-lg"></i>
          Volver a la lista
        </button>
      </div>

      {reqStatus === false && (
        <div className="alert alert-danger border text-center w-100 p-3">
          Un error ha ocurrido, inténtalo más tarde
        </div>
      )}

      {module === 'contacts' && (
        <ContactsForm
          id={itemId}
          title={title}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          setData={setData}
          loading={formLoading}
          error={error}
          {...data}
        />
      )}

      {module === 'trabajadores' && (
        <TrabajadoresForm
          id={itemId}
          title={title}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          setData={setData}
          loading={formLoading}
          error={error}
          busId={id}
          {...data}
        />
      )}

      {module === 'equipos' && (
        <EquiposForm
          id={itemId}
          title={title}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          setData={setData}
          loading={formLoading}
          error={error}
          busId={id}
          {...data}
        />
      )}

      {module === 'proveedores' && (
        <ProveedoresForm
          id={itemId}
          title={title}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          setData={setData}
          loading={formLoading}
          error={error}
          {...data}
        />
      )}

      {(module === 'users_proveedor' || module === 'users_business') && (
        <UsersFormByBusiness
          id={itemId}
          busId={id}
          title={title}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          setData={setData}
          loading={formLoading}
          error={error}
          parentBusiness={parentBusiness}
          {...data}
        />
      )}

      {module === 'clasificacion' && (
        <ClasificacionForm
          id={itemId}
          title={title}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          setData={setData}
          loading={formLoading}
          error={error}
          busId={id}
          {...data}
        />
      )}
    </div>
  );
}
